import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Carousel from 'react-multi-carousel';
import {
  useMediaQuery,
  Grid,
  Typography,
  Button,
  colors,
} from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import { Image, IconText, LearnMoreLink } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardProduct } from 'components/organisms';
import { useQuery } from '@apollo/client';
import { RANDROM_PRODUCTS_GQL } from 'utils/queries';
import { LocalizationStore } from 'states';
const useStyles = makeStyles(() => ({
  root: {},
  fontWeight700: {
    fontWeight: 700,
  },
  coverImage: {
    objectFit: 'cover',
    width: '100%',
    height: 250,
  },
  carouselItem: {
    padding: '50px 10px',
  },
  cardContent: {
    padding: 20,
  },
}));

const RandomProducts = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { loading, error, data } = useQuery(RANDROM_PRODUCTS_GQL);
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  if (loading) return null;
  if (error) return null;

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={locale.language === 'Türkçe' ? 'ÜRÜNLERİMİZ' : 'OUR PRODUCTS'}
      />

      <Carousel
        arrows={true}
        additionalTransfrom={0}
        autoPlaySpeed={2000}
        centerMode={false}
        focusOnSelect={false}
        infinite
        itemClass={classes.carouselItem}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          superLargeDesktop: {
            breakpoint: { max: 3000, min: 1300 },
            items: 5,
          },
          desktop: {
            breakpoint: {
              max: 1300,
              min: 980,
            },
            items: 4,
            partialVisibilityGutter: 40,
          },
          tablet: {
            breakpoint: {
              max: 980,
              min: 600,
            },
            items: 3,
            partialVisibilityGutter: 30,
          },
          mobile: {
            breakpoint: {
              max: 600,
              min: 480,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
          miniMobile: {
            breakpoint: {
              max: 480,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
        }}
        showDots={false}
      >
        {data.ndts.map((item, index) => (
          <CardProduct
            contentClassName={classes.cardContent}
            withShadow
            mediaClassName={classes.coverImage}
            mediaContent={
              <Image
                className={classes.coverImage}
                src={`${process.env.REACT_APP_BACKEND_URL}${item.image[0].url}`}
                alt={item.nameTR}
                lazyProps={{
                  width: '100%',
                  height: 250,
                }}
              />
            }
            cardContent={
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    color="textPrimary"
                    align="left"
                    className={classes.fontWeight700}
                  >
                    {locale.language === 'Türkçe' ? item.nameTR : item.nameEN}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <LearnMoreLink
                    href={`/urun/${item.seoUrl}`}
                    title={
                      locale.language === 'Türkçe'
                        ? 'Ürüne Git'
                        : 'Go to product'
                    }
                    variant="body1"
                    color="textSecondary"
                  />
                </Grid>
              </Grid>
            }
          />
        ))}
      </Carousel>
    </div>
  );
};

export default RandomProducts;
