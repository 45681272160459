import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  Popover,
  Typography,
  IconButton,
  colors,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import { TranslateButton, LoadingCircular } from 'components/molecules';
import { Image } from 'components/atoms';
import { LocalizationStore } from 'states';
import { useQuery } from '@apollo/client';
import { CATEGORIES_SUB_CATEGORIES } from 'utils/queries';
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    background: theme.palette.white,
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar: {
    maxWidth: 1400,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  navLink: {
    fontWeight: 300,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark,
    },
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    minWidth: 350,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 50,
    height: 50,
    [theme.breakpoints.up('md')]: {
      width: 60,
      height: 60,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, pages, ...rest } = props;

  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const handleClick = (event, popoverId) => {
    setAnchorEl(event.target);
    setOpenedPopoverId(popoverId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const ProductCategories = (props) => {
    const { loading, error, data } = useQuery(CATEGORIES_SUB_CATEGORIES);

    if (loading) return <LoadingCircular />;
    if (error) return <p>Error :(</p>;

    return (
      <div className={classes.menu}>
        {data.categories.map((item, i) => {
          return (
            <div className={classes.menuItem} key={i}>
              <List disablePadding>
                <ListItem disableGutters>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.menuGroupTitle}
                  >
                    {locale.language === 'Türkçe' ? item.nameTR : item.nameEN}
                  </Typography>
                </ListItem>
                {item.sub_categories.map((subCategory, i) => {
                  const uri = item.seoUrl;
                  const sUri = subCategory.seoUrl;
                  return (
                    <ListItem
                      disableGutters
                      key={i}
                      className={classes.menuGroupItem}
                    >
                      <Typography
                        variant="body1"
                        component={RouterLink}
                        to={`/urunler/${uri}/${sUri}`}
                        className={clsx(classes.navLink, 'submenu-item')}
                        color="textSecondary"
                        onClick={handleClose}
                      >
                        {locale.language === 'Türkçe'
                          ? subCategory.nameTR
                          : subCategory.nameEN}
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <AppBar
      {...rest}
      position="relative"
      className={clsx(classes.root, className)}
    >
      <Toolbar disableGutters className={classes.toolbar}>
        <Typography
          component={RouterLink}
          to="/"
          className={classes.logoContainer}
        >
          <Image
            className={classes.logoImage}
            src="/images/logos/netan-logo.jpg"
            alt="thefront"
            lazy={false}
          />
        </Typography>
        <div className={classes.flexGrow} />
        <Hidden xsDown>
          <List className={classes.navigationContainer}>
            <ListItem className={classes.listItem}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.listItemText}
                component={RouterLink}
                to="/hakkimizda"
              >
                {locale.topBar.about}
              </Typography>
            </ListItem>
            <div>
              <ListItem
                aria-describedby={'0'}
                onClick={(e) => handleClick(e, 0)}
                className={clsx(
                  classes.listItem,
                  openedPopoverId === 0 ? classes.listItemActive : '',
                )}
              >
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={clsx(classes.listItemText, 'menu-item')}
                >
                  {locale.topBar.products}
                </Typography>
                <ListItemIcon className={classes.listItemIcon}>
                  <ExpandMoreIcon
                    className={openedPopoverId === 0 ? classes.expandOpen : ''}
                    fontSize="small"
                  />
                </ListItemIcon>
              </ListItem>
              <Popover
                elevation={1}
                id={0}
                open={openedPopoverId === 0}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                classes={{ paper: classes.popover }}
              >
                <ProductCategories />
              </Popover>
            </div>

            <ListItem className={classes.listItem}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.listItemText}
                component={RouterLink}
                to="/iletisim"
              >
                {locale.topBar.contact}
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <TranslateButton />
            </ListItem>
          </List>
        </Hidden>
        <Hidden smUp>
          <div style={{ marginRight: 20 }}>
            <TranslateButton />
          </div>

          <IconButton
            className={classes.iconButton}
            onClick={onSidebarOpen}
            aria-label="Menu"
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
};

export default Topbar;
