import { colors } from '@material-ui/core';

export default {
  language: 'Türkçe',
  topBar: {
    about: 'Hakkımızda',
    products: 'Ürünler',
    contact: 'İletişim',
    languages: ['Türkçe', 'English'],
  },
  home: {
    slider: {
      titleLeft: 'Sizin için',
      titleRight: ' yapıyoruz',
      info:
        'Tecrübesi ve her gün yenilenen teknolojisiyle beraber kendini sürekli geliştiren firmamız sizlere kaliteli hizmet ayrıcalığını, en iyi teknolojiyi ve daha birçok günümüz teknolojisine paralel hizmetleri ile sunar.',
      button1: 'Hakkımızda',
      button2: 'Ürünler',
    },
    advantages: {
      titleLeft: 'HAKKIMIZDA',
      titleRight: ' ',
      subTitle:
        'NETAN, Tahribatsız Muayene, Endüstriyel Güvenlik ve Tren Teknoloji Sistemleri , otomotiv, enerji, demir çelik ve döküm endüstrisi gibi sektörlere; ürün, sistem ve çözüm hizmetleri sunmaktadır. Tecrübesi ve her gün yenilenen teknolojisiyle beraber kendini sürekli geliştiren firmamız sizlere kaliteli hizmet ayrıcalığını, en iyi teknolojiyi ve daha birçok günümüz teknolojisine paralel hizmetleri ile sunar.',
    },
    promoNumbers: {
      titleLeft: 'Sektöründe Öncü',
      titleRight: ' Firma',
      subTitle: 'Mühendislik çözümlerini müşteri odaklı sunmaktayız.',
      data: [
        {
          color: colors.blue,
          icon: 'fa fa-mobile',
          title: '1000+',
          subtitle: 'Teknoloji',
          description:
            'Tahribatsız muayene, endüstriyel güvenlik ve tren teknoloji sistemleri',
        },
        {
          color: colors.purple,
          icon: 'fa fa-users',
          title: '800+',
          subtitle: 'Müşteri',
          description: 'Müşteri Odaklı Yaklaşım',
        },
        {
          color: colors.pink,
          icon: 'fa fa-linode',
          title: '600+',
          subtitle: 'Kiralama',
          description: 'İhtiyacınız olan ürünleri kiralama imkanı',
        },
        {
          color: colors.indigo,
          icon: 'fa fa-thumbs-o-up',
          title: '400+',
          subtitle: 'İşbirliği',
          description: 'Öncü firmalarla işbirliği',
        },
      ],
    },
    productTypes: {
      title: 'Ürün Gruplarımız',
      subTitle: 'Sizlere hizmet verdiğimiz 3 ana ürün grubu',
      ndt: 'NDT',
      energy: 'Enerji',
      railway: 'Demiryolu',
    },
  },
  contact: {
    title: 'Her şey için bize ulaşın',
    subTitle: 'Hedefimiz olabildiğince yardımcı olmak.',
    phone: 'Telefon',
    email: 'E-posta',
    adress: 'Adres',
    subAdress:
      '100. Yıl Mah. Reşit Galip Cad. Gölgeli Sk. No: 8/2 Gaziosmanpaşa - Çankaya - Ankara / TÜRKİYE',
  },
};
