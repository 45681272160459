import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import {
  Translate as TranslateIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import EnglishIcon from 'assets/flags/english';
import TurkishIcon from 'assets/flags/turkish';
import { trTR, enEN } from 'locale';
import { LocalizationStore } from 'states';
const TranslateButton = () => {
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseTR = () => {
    LocalizationStore.update((s) => {
      s.locale = trTR;
    });
    localStorage.setItem('language', 'trTR');
    setAnchorEl(null);
  };
  const handleCloseEN = () => {
    LocalizationStore.update((s) => {
      s.locale = enEN;
    });
    localStorage.setItem('language', 'enEN');
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<TranslateIcon />}
        endIcon={<ExpandMoreIcon />}
        variant="text"
      >
        {locale.language}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCloseTR}>
          <TurkishIcon style={{ marginRight: 20, width: 24, height: 24 }} />
          Türkçe
        </MenuItem>
        <MenuItem onClick={handleCloseEN}>
          <EnglishIcon style={{ marginRight: 20, width: 24, height: 24 }} />
          English
        </MenuItem>
      </Menu>
    </>
  );
};
export default TranslateButton;
