import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingCircular } from 'components/molecules';
import { Folio } from './components';
import { useQuery } from '@apollo/client';
import {
  CATEGORIES_GQL,
  SUB_CATEGORIES_GQL,
  GROUPS_GQL,
  SUB_GROUPS_GQL,
  SUB_GROUPS_PRODUCTS_GQL,
} from 'utils/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionBreadcrumb: {
    '& .section-alternate__content': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

const SubGroupProducts = (props) => {
  //.com/urunler/ntds
  const { subGroup, path } = props;
  const { loading, error, data } = useQuery(SUB_GROUPS_PRODUCTS_GQL(subGroup));
  if (loading) return <LoadingCircular />;
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <Folio data={data.subGroups[0].products} path={path} product />
    </div>
  );
};

const SubGroups = (props) => {
  //.com/urunler/ntds/ndts-1/ndts-1-1
  const { group, path } = props;
  const { loading, error, data } = useQuery(SUB_GROUPS_GQL(group));
  if (loading) return <LoadingCircular />;
  if (error) return <p>Error :(</p>;
  const notGroupedData = data.groups[0].products
    .filter((item) => item.sub_group === null)
    .map((item) => ({ ...item, type: 'product' }));

  const folioData = [...notGroupedData, ...data.groups[0].sub_groups];

  if (data.groups[0].sub_groups.length === 0)
    return (
      <div>
        <Folio data={data.groups[0].products} path={path} product />
      </div>
    );
  return (
    <div>
      <Folio data={folioData} path={path} />
    </div>
  );
};
const Groups = (props) => {
  //.com/urunler/ntds/ndts-1
  const { subCategory, path } = props;
  const { loading, error, data } = useQuery(GROUPS_GQL(subCategory));
  if (loading) return <LoadingCircular />;
  if (error) return <p>Error :(</p>;
  const notGroupedData = data.subCategories[0].products
    .filter((item) => item.group === null)
    .map((item) => ({ ...item, type: 'product' }));

  const folioData = [...notGroupedData, ...data.subCategories[0].groups];

  if (data.subCategories[0].groups.length === 0)
    return (
      <div>
        <Folio data={data.subCategories[0].products} path={path} product />
      </div>
    );
  return (
    <div>
      <Folio data={folioData} path={path} />
    </div>
  );
};

const SubCategories = (props) => {
  //.com/urunler/ntds
  const { category, path } = props;
  const { loading, error, data } = useQuery(SUB_CATEGORIES_GQL(category));
  if (loading) return <LoadingCircular />;
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <Folio data={data.categories[0].sub_categories} path={path} />
    </div>
  );
};

const Categories = (props) => {
  const { path } = props;
  const { loading, error, data } = useQuery(CATEGORIES_GQL);

  if (loading) return <LoadingCircular />;
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <Folio data={data.categories} path={path} />
    </div>
  );
};

const Products = () => {
  let { category, subCategory, group, subGroup } = useParams();

  if (subGroup)
    return (
      <SubGroupProducts
        subGroup={subGroup}
        path={`/urunler/${category}/${subCategory}/${group}/${subGroup}`}
      />
    );
  if (group)
    return (
      <SubGroups
        group={group}
        path={`/urunler/${category}/${subCategory}/${group}`}
      />
    );
  if (subCategory)
    return (
      <Groups
        subCategory={subCategory}
        path={`/urunler/${category}/${subCategory}`}
      />
    );
  if (category)
    return <SubCategories category={category} path={`/urunler/${category}`} />;

  return <Categories path={`/urunler`} />;
};

export default Products;
