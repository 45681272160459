import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Section, SectionAlternate } from 'components/organisms';
import { LoadingCircular } from 'components/molecules';
import {
  Hero,
  Advantages,
  PromoNumbers,
  Slider,
  RandomProducts,
  Features,
} from './components';
import { useQuery } from '@apollo/client';
import { HOME_GQL } from 'utils/queries';

const useStyles = makeStyles((theme) => ({
  root: {},
  slider: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  featuresSection: {
    background: 'url(/images/illustrations/patterns-bg.svg) no-repeat center',
    backgroundSize: 'contain',
  },
  sectionPadding: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
  },
}));

const Home = () => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(HOME_GQL);

  if (loading) return <LoadingCircular />;
  if (error) return <p>Error :(</p>;

  return (
    <div className={classes.root}>
      <Section>
        <Slider slides={data.home.Slider} />
      </Section>
      <SectionAlternate>
        <Advantages />
      </SectionAlternate>
      <div className={classes.featuresSection}>
        <Section className={classes.sectionPadding}>
          <Features />
        </Section>
      </div>
      <SectionAlternate>
        <RandomProducts />
      </SectionAlternate>
    </div>
  );
};

export default Home;
