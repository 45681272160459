import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, colors } from '@material-ui/core';
import { Icon } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { DescriptionListIcon } from 'components/organisms';
import { LocalizationStore } from 'states';
const useStyles = makeStyles(() => ({
  root: {},
}));

const Advantages = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={
          <span>
            {locale.home.advantages.titleLeft}
            <span className="text-highlighted__primary">
              {locale.home.advantages.titleRight}
            </span>
          </span>
        }
        subtitle={locale.home.advantages.subTitle}
        fadeUp
      />
    </div>
  );
};

Advantages.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
};

export default Advantages;
