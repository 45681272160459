import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, colors } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { DescriptionListIcon, CardBase } from 'components/organisms';
import { useQuery } from '@apollo/client';
import { CATEGORIES_GQL } from 'utils/queries';
import { LocalizationStore } from 'states';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {},
  coverImage: {
    objectFit: 'cover',
  },
}));

const Features = (props) => {
  let history = useHistory();
  const { className, ...rest } = props;
  const classes = useStyles();
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { loading, error, data } = useQuery(CATEGORIES_GQL);

  if (loading) return null;
  if (error) return null;

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={locale.language === 'Türkçe' ? 'SEKTÖRLERİMİZ' : 'OUR SECTORS'}
      />
      <Grid container spacing={isMd ? 4 : 2}>
        {data.categories.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} data-aos="fade-up">
            <CardBase
              liftUp
              noShadow
              variant="outlined"
              onClick={() => history.push(`/urunler/${item.seoUrl}`)}
            >
              <DescriptionListIcon
                icon={
                  <Image
                    className={classes.coverImage}
                    src={`${process.env.REACT_APP_BACKEND_URL}${item.image.url}`}
                    alt={item.nameTR}
                    lazyProps={{
                      width: '100%',
                      height: 250,
                    }}
                  />
                }
                title={locale.language === 'Türkçe' ? item.nameTR : item.nameEN}
                align="left"
              />
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Features.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
};

export default Features;
