import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import { SectionHeader, LoadingCircular } from 'components/molecules';
import { HeroShaped, Map } from 'components/organisms';
import { LocalizationStore } from 'states';
import { useQuery } from '@apollo/client';
import { CONTACT_GQL } from 'utils/queries';
const useStyles = makeStyles((theme) => ({
  root: {},
  map: {
    zIndex: 9,
  },
  form: {
    '& .MuiTextField-root': {
      background: 'white',
    },
    '& .MuiOutlinedInput-input': {
      background: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      boxShadow: 'inset 0 1px 0 0 rgba(255, 255, 255, 0.1)',
      border: 'solid 1px rgba(0, 0, 0, 0.2)',
    },
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  heroleftSide: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(6),
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  listItemText: {},
  listItem: {
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  icon: {
    background: 'transparent',
    borderRadius: 0,
  },
}));

const Form = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));
  const { loading, error, data } = useQuery(CONTACT_GQL);
  if (loading) return <LoadingCircular />;
  if (error) return <p>Error :(</p>;

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <HeroShaped
        leftSide={
          <div className={classes.heroleftSide}>
            <SectionHeader
              title={locale.contact.title}
              subtitle={locale.contact.subTitle}
              data-aos="fade-up"
              align="left"
            />
            <div className={classes.form}>
              <List disablePadding className={classes.list}>
                <ListItem
                  disableGutters
                  data-aos="fade-up"
                  className={classes.listItem}
                >
                  <ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar
                      src="/images/illustrations/contact-icon-phone.png"
                      srcSet="/images/illustrations/contact-icon-phone@2x.png 2x"
                      className={classes.icon}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.listItemText}
                    primary={locale.contact.phone}
                    secondary={data.contact.phone}
                    primaryTypographyProps={{
                      className: classes.title,
                      variant: 'subtitle1',
                      color: 'textSecondary',
                    }}
                    secondaryTypographyProps={{
                      variant: 'subtitle1',
                      color: 'textPrimary',
                      component: 'span',
                    }}
                  />
                </ListItem>
                <ListItem
                  disableGutters
                  data-aos="fade-up"
                  className={classes.listItem}
                >
                  <ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar
                      src="/images/illustrations/contact-icon-mail.png"
                      srcSet="/images/illustrations/contact-icon-mail@2x.png 2x"
                      className={classes.icon}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.listItemText}
                    primary="Fax"
                    secondary={data.contact.fax}
                    primaryTypographyProps={{
                      className: classes.title,
                      variant: 'subtitle1',
                      color: 'textSecondary',
                    }}
                    secondaryTypographyProps={{
                      variant: 'subtitle1',
                      color: 'textPrimary',
                    }}
                  />
                </ListItem>
                <ListItem
                  disableGutters
                  data-aos="fade-up"
                  className={classes.listItem}
                >
                  <ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar
                      src="/images/illustrations/contact-icon-mail.png"
                      srcSet="/images/illustrations/contact-icon-mail@2x.png 2x"
                      className={classes.icon}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.listItemText}
                    primary={locale.contact.email}
                    secondary={data.contact.email}
                    primaryTypographyProps={{
                      className: classes.title,
                      variant: 'subtitle1',
                      color: 'textSecondary',
                    }}
                    secondaryTypographyProps={{
                      variant: 'subtitle1',
                      color: 'textPrimary',
                    }}
                  />
                </ListItem>
                <ListItem
                  disableGutters
                  data-aos="fade-up"
                  className={classes.listItem}
                >
                  <ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar
                      src="/images/illustrations/contact-icon-pin.png"
                      srcSet="/images/illustrations/contact-icon-pin@2x.png 2x"
                      className={classes.icon}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.listItemText}
                    primary={locale.contact.adress}
                    secondary={
                      locale.language === 'Türkçe'
                        ? data.contact.adressTR
                        : data.contact.adressEN
                    }
                    primaryTypographyProps={{
                      className: classes.title,
                      variant: 'subtitle1',
                      color: 'textSecondary',
                    }}
                    secondaryTypographyProps={{
                      variant: 'subtitle1',
                      color: 'textPrimary',
                    }}
                  />
                </ListItem>
              </List>
            </div>
          </div>
        }
        rightSide={
          <Map
            zoom={20}
            center={[data.contact.locationN, data.contact.locationE]}
            pins={[
              {
                location: {
                  y: data.contact.locationN,
                  x: data.contact.locationE,
                  address: locale.contact.subAdress,
                },
              },
            ]}
            className={classes.map}
          />
        }
      />
    </div>
  );
};

Form.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
};

export default Form;
