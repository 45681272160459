import React from 'react';
import ReactMarkdown from 'react-markdown';
import { CheckCircle } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
const Markdown = (props) => {
  const { source } = props;
  return (
    <ReactMarkdown
      source={source}
      renderers={{
        paragraph: ({ children }) => (
          <Typography gutterBottom>{children}</Typography>
        ),
        listItem: ({ children }) => (
          <div style={{ display: 'flex', marginBottom: 10 }}>
            <CheckCircle color="primary" style={{ marginRight: 10 }} />
            <Typography>{children}</Typography>
          </div>
        ),
        heading: (props) => {
          return (
            <Typography variant={`h${props.level}`} gutterBottom>
              {props.children}
            </Typography>
          );
        },
      }}
    />
  );
};

export default Markdown;
