import { colors } from '@material-ui/core';

export default {
  language: 'English',
  topBar: {
    about: 'About',
    products: 'Products',
    contact: 'Contact',
    languages: ['Türkçe', 'English'],
  },
  home: {
    slider: {
      titleLeft: 'We do it',
      titleRight: ' for you.',
      info:
        'Our company, which constantly improves itself with its experience and technology renewed every day, offers you the privilege of quality service, the best technology and services parallel to many other technologies.',
      button1: 'About',
      button2: 'Products',
    },
    advantages: {
      titleLeft: 'ABOUT US',
      titleRight: '',
      subTitle:
        'NETAN, Non-Destructive Testing, Industrial Safety and Train Technology Systems, industries such as automotive, energy, iron and steel and foundry industries; offers product, system and solution services. Our company, which constantly improves itself with its experience and technology that is renewed every day, offers you the privilege of quality service, the best technology and many more parallel services to todays technology.',
    },
    promoNumbers: {
      titleLeft: 'Leading Company',
      titleRight: ' in its Sector',
      subTitle: 'We offer engineering solutions customer-focused.',
      data: [
        {
          color: colors.blue,
          icon: 'fa fa-mobile',
          title: '1000+',
          subtitle: 'Tecnology',
          description:
            'Nondestructive testing, industrial security and trend technology systems',
        },
        {
          color: colors.purple,
          icon: 'fa fa-users',
          title: '800+',
          subtitle: 'Customer',
          description: 'Customer Oriented Approach',
        },
        {
          color: colors.pink,
          icon: 'fa fa-linode',
          title: '600+',
          subtitle: 'Rent',
          description: 'The opportunity to rent the products you need',
        },
        {
          color: colors.indigo,
          icon: 'fa fa-thumbs-o-up',
          title: '400+',
          subtitle: 'Cooperation',
          description: 'Cooperation with leading companies',
        },
      ],
    },
    productTypes: {
      title: 'Our Product Groups',
      subTitle: '3 main product groups we serve you',
      ndt: 'NDT',
      energy: 'Energy',
      railway: 'Railway',
    },
  },
  contact: {
    title: 'Contact us for anything',
    subTitle: 'Our goal is to be as helpful as possible.',
    phone: 'Phone',
    email: 'E-mail',
    adress: 'Adress',
    subAdress:
      '100. Yıl neighborhood, Reşit Galip-Gölgeli St. No: 8/2 Gaziosmanpaşa - Çankaya - Ankara / TURKEY',
  },
};
