import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { LocalizationStore } from 'states';
import { Image } from 'components/atoms';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  navLink: {
    fontWeight: 300,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  closeIcon: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  menu: {
    display: 'flex',
  },
  menuItem: {
    marginRight: theme.spacing(8),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
  divider: {
    width: '100%',
  },
  logoContainer: {
    marginBottom: 50,
    width: 100,
    height: 100,
    [theme.breakpoints.up('md')]: {
      width: 1000,
      height: 100,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { onClose, className, ...rest } = props;
  const classes = useStyles();
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));
  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <ListItem className={classes.closeIcon} onClick={onClose}>
        <ListItemIcon className={classes.listItemIcon}>
          <CloseIcon fontSize="small" />
        </ListItemIcon>
      </ListItem>
      <ListItem className={classes.listItem} onClick={onClose}>
        <Typography
          component={RouterLink}
          to="/"
          className={classes.logoContainer}
        >
          <Image
            className={classes.logoImage}
            src="/images/logos/netan-logo.jpg"
            alt="thefront"
            lazy={false}
          />
        </Typography>
      </ListItem>
      <ListItem className={classes.listItem} onClick={onClose}>
        <Typography
          variant="h6"
          color="primary"
          component={CustomRouterLink}
          to="/hakkimizda"
        >
          {locale.language === 'Türkçe' ? 'HAKKIMIZDA' : 'ABOUT'}
        </Typography>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Divider className={classes.divider} />
      </ListItem>
      <ListItem className={classes.listItem} onClick={onClose}>
        <Typography
          variant="h6"
          color="primary"
          component={CustomRouterLink}
          to="/urunler"
        >
          {locale.language === 'Türkçe' ? 'Ürünler' : 'Products'}
        </Typography>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Divider className={classes.divider} />
      </ListItem>
      <ListItem className={classes.listItem} onClick={onClose}>
        <Typography
          variant="h6"
          color="primary"
          component={CustomRouterLink}
          to="/iletisim"
        >
          {locale.language === 'Türkçe' ? 'İletişim' : 'Contact'}
        </Typography>
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default SidebarNav;
