import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Button } from '@material-ui/core';
import { LocalizationStore } from 'states';
import { SectionHeader, SwiperImage } from 'components/molecules';
import { HeroShaped } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {},
  swiperNavButton: {
    width: `${theme.spacing(3)}px !important`,
    height: `${theme.spacing(3)}px !important`,
    padding: `${theme.spacing(2)}px !important`,
  },
}));

const Hero = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <HeroShaped
        leftSide={
          <SectionHeader
            title={
              <span>
                {locale.home.slider.titleLeft}
                <span className="text-highlighted__primary">
                  {locale.home.slider.titleRight}
                </span>
              </span>
            }
            subtitle={locale.home.slider.info}
            ctaGroup={[
              <Button
                variant="contained"
                color="primary"
                size={isMd ? 'large' : 'medium'}
              >
                {locale.home.slider.button1}
              </Button>,
              <Button
                component={RouterLink}
                to="/urunler"
                variant="outlined"
                color="primary"
                size={isMd ? 'large' : 'medium'}
              >
                {locale.home.slider.button2}
              </Button>,
            ]}
            align="left"
            titleVariant="h3"
          />
        }
        rightSide={
          <SwiperImage
            navigationButtonStyle={classes.swiperNavButton}
            items={[
              {
                src: '/images/photos/slider/slide2.jpg',
                srcSet: '/images/photos/slider/slide2.jpg',
                alt: '...',
              },
              {
                src: '/images/photos/slider/slide3.jpg',
                srcSet: '/images/photos/slider/slide3.jpg',
                alt: '...',
              },
              {
                src: '/images/photos/coworking/place3.jpg',
                srcSet: '/images/photos/slider/slide1.jpg',
                alt: '...',
              },
            ]}
          />
        }
      />
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
