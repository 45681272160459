import React from 'react';
import { CircularProgress } from '@material-ui/core';

const LoadingCircular = (props) => {
  return (
    <div
      style={{
        width: '100%',
        minHeight: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}
    >
      <CircularProgress />
    </div>
  );
};

export default LoadingCircular;
