import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardPromo } from 'components/organisms';
import { LocalizationStore } from 'states';
const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100vw',
  },
}));

const PromoNumbers = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={
          <span>
            {locale.home.promoNumbers.titleLeft}
            <span className="text-highlighted__primary">
              {locale.home.promoNumbers.titleRight}
            </span>
          </span>
        }
        subtitle={locale.home.promoNumbers.subTitle}
        fadeUp
      />
      <Grid container spacing={isMd ? 4 : 2}>
        {locale.home.promoNumbers.data.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={3}
            data-aos="fade-top"
          >
            <CardPromo
              variant="outlined"
              liftUp
              align={isMd ? 'center' : 'center'}
              title={item.title}
              subtitle={item.subtitle}
              description={item.description}
              fontIconClass={item.icon}
              color={item.color}
              titleColor="primary"
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

PromoNumbers.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
};

export default PromoNumbers;
