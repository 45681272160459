import React from 'react';
import clsx from 'clsx';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SectionHeader } from 'components/molecules';
import { Section, Parallax } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: 'white',
    overflow: 'hidden',
  },
  sectionWrapper: {
    padding: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#003c0580',
  },
  textWhite: {
    color: 'white',
  },
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(4),
    },
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    height: 60,
    width: 60,
  },
}));

const Hero = (props) => {
  const { className, cover, title, subtitle, author, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Parallax backgroundImage={cover} blur>
        <div className={classes.sectionWrapper}>
          <Container maxWidth="lg">
            <SectionHeader
              title={title}
              align="left"
              data-aos="fade-up"
              titleProps={{
                className: clsx(classes.title, classes.textWhite),
                variant: 'h4',
              }}
            />
          </Container>
        </div>
      </Parallax>
    </div>
  );
};

export default Hero;
