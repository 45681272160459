import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './common';
import { Main as MainLayout } from './layouts';

import {
  Home as HomeView,
  NotFound as NotFoundView,
  Contact as ContactView,
  Products as ProductsView,
  Product as ProductView,
  About as AboutView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path="/"
      />

      <RouteWithLayout
        component={ProductsView}
        exact
        layout={MainLayout}
        path="/urunler/:category?/:subCategory?/:group?/:subGroup?"
      />
      <RouteWithLayout
        component={ProductView}
        exact
        layout={MainLayout}
        path="/urun/:productID?"
      />
      <RouteWithLayout
        component={ContactView}
        exact
        layout={MainLayout}
        path="/iletisim"
      />
      <RouteWithLayout
        component={AboutView}
        exact
        layout={MainLayout}
        path="/hakkimizda"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MainLayout}
        path="/sayfa-bulunamadi"
      />

      <Redirect to="/sayfa-bulunamadi" status="404" />
    </Switch>
  );
};

export default Routes;
