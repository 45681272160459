import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Divider, Button } from '@material-ui/core';
import { SectionHeader, LoadingCircular } from 'components/molecules';
import { Section, CardBase } from 'components/organisms';
import { Markdown } from 'utils';
import { LocalizationStore } from 'states';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ABOUT_GQL } from 'utils/queries';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    '& .description-cta__button-group': {
      flexWrap: 'nowrap',
    },
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  textWhite: {
    color: 'white',
  },
  cardHighlighted: {
    background: theme.palette.primary.main,
  },
  checkBox: {
    background: 'transparent',
    borderRadius: 0,
    width: 30,
    height: 30,
  },
  list: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

const CompanyTerms = () => {
  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));

  const { loading, error, data } = useQuery(ABOUT_GQL);
  if (loading) return <LoadingCircular />;
  if (error) return <p>Error :(</p>;
  console.log(data);
  return (
    <div className={classes.root}>
      <Section className={classes.pagePaddingTop}>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={8}>
            <SectionHeader
              title={locale.language === 'Türkçe' ? 'HAKKIMIZDA' : 'ABOUT'}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: 'textPrimary',
              }}
            />
            <Markdown
              source={
                locale.language === 'Türkçe'
                  ? data.about.infoTR
                  : data.about.infoEN
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={isMd ? 4 : 2} direction="column">
              <Grid item xs={12}>
                <CardBase withShadow className={classes.cardHighlighted}>
                  <SectionHeader
                    title={
                      locale.language === 'Türkçe'
                        ? data.about.contactTitleTR
                        : data.about.concactTitleEN
                    }
                    subtitle={
                      locale.language === 'Türkçe'
                        ? data.about.contactInfoTR
                        : data.about.contactInfoEN
                    }
                    ctaGroup={[
                      <Button
                        variant="contained"
                        onClick={() => history.push(`/iletisim`)}
                      >
                        {locale.language === 'Türkçe'
                          ? data.about.contactUsTR
                          : data.about.contactUsEN}
                      </Button>,
                    ]}
                    disableGutter
                    align="left"
                    titleProps={{
                      variant: 'subtitle1',
                      className: clsx(
                        classes.textWhite,
                        classes.fontWeightBold,
                      ),
                    }}
                    subtitleProps={{
                      variant: 'body2',
                      className: classes.textWhite,
                    }}
                  />
                </CardBase>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      <Divider />
    </div>
  );
};

export default CompanyTerms;
