import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Fab } from '@material-ui/core';
import { Topbar, Footer, Sidebar } from './components';
import { useHistory } from 'react-router-dom';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { LocalizationStore } from 'states';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 100000,
  },
}));

const Main = (props) => {
  const { children } = props;
  const classes = useStyles();
  let history = useHistory();
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar onClose={handleSidebarClose} open={open} variant="temporary" />
      <main>{children}</main>
      <Footer />
      <Fab
        variant="extended"
        color="primary"
        className={classes.fab}
        onClick={() => {
          history.push('/iletisim');
        }}
      >
        <ImportContactsIcon className={classes.extendedIcon} />
        {locale.language === 'Türkçe' ? 'BİZE ULAŞIN' : ' CONTACT US'}
      </Fab>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
