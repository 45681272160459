import React, { useEffect, useState } from 'react';
import {
  Paper,
  makeStyles,
  Typography,
  IconButton,
  Box,
  Hidden,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { LocalizationStore } from 'states';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  swiper: {
    width: '100%',
    position: 'relative',
  },
  img: {
    width: '100%',
    height: 500,
    objectFit: 'cover',
    display: 'block',
  },
  imgOverlay: {
    position: 'absolute',
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    height: ' 100%',
    width: '100%',
    zIndex: 100,
    flexDirection: 'column',
  },
  imageOverlayContainer: {
    maxWidth: 1300,
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  titleContainer: {
    height: '100%',
    display: 'flex',
    left: theme.spacing(40),
  },
  text: {
    color: '#fff',
  },
  text2: {
    color: 'rgba(255,255,255,0.9)',
  },
  leftArrow: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  rightArrow: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    backgroundColor: '#fff',
  },
}));

const SectionSlider = (props) => {
  const classes = useStyles();
  const { slides } = props;
  const [activeStep, setActiveStep] = useState(1);
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const downStepChange = () => {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);
    } else {
      setActiveStep(slides.length - 1);
    }
  };

  const upStepChange = () => {
    if (activeStep !== slides.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      setActiveStep(0);
    }
  };

  return (
    <Box
      position="relative"
      data-aos="fade-up"
      borderRadius={8}
      overflow="hidden"
    >
      <AutoPlaySwipeableViews
        interval={7000}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {slides.map((item, index) => (
          <Paper key={index} className={classes.swiper}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img
                className={classes.img}
                src={`${process.env.REACT_APP_BACKEND_URL}${item.image.url}`}
                alt={item.titleTR}
              />
            ) : null}
            <Box className={classes.imgOverlay}>
              <Box className={classes.imageOverlayContainer}>
                <Hidden smUp>
                  <Typography
                    variant="h3"
                    className={classes.text}
                    style={{ width: '100%' }}
                    align="center"
                  >
                    {locale.language === 'Türkçe' ? item.titleTR : item.titleEN}
                  </Typography>
                </Hidden>
                <Hidden xsDown>
                  <Typography
                    variant="h3"
                    className={classes.text}
                    gutterBottom
                  >
                    {locale.language === 'Türkçe' ? item.titleTR : item.titleEN}
                  </Typography>

                  <Typography variant="h6" className={classes.text2}>
                    {locale.language === 'Türkçe'
                      ? item.subTitleTR
                      : item.subTitleEN}
                  </Typography>
                </Hidden>
              </Box>
            </Box>
          </Paper>
        ))}
      </AutoPlaySwipeableViews>
      <Box className={classes.leftArrow}>
        <IconButton
          size="small"
          aria-label="delete"
          onClick={downStepChange}
          className={classes.iconButton}
        >
          <ArrowLeft fontSize="large" />
        </IconButton>
      </Box>
      <Box className={classes.rightArrow}>
        <IconButton
          size="small"
          aria-label="delete"
          onClick={upStepChange}
          className={classes.iconButton}
        >
          <ArrowRight fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SectionSlider;
