import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import AOS from 'aos';
import theme from './theme';
import Routes from './Routes';
import 'swiper/css/swiper.min.css';
import 'aos/dist/aos.css';
import 'react-multi-carousel/lib/styles.css';
import './assets/scss/index.scss';
import { LocalizationStore } from './states';
import { trTR, enEN } from './locale';
const browserHistory = createBrowserHistory();

browserHistory.listen((location) => {
  setTimeout(() => {
    if (location.action === 'POP') {
      return;
    }
    window.scrollTo(0, 0);
  });
});

const App = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      delay: 50,
      duration: 550,
      easing: 'ease-in-out',
    });
    const language = localStorage.getItem('language');
    language === 'enEN'
      ? LocalizationStore.update((s) => {
          s.locale = enEN;
        })
      : LocalizationStore.update((s) => {
          s.locale = trTR;
        });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </ThemeProvider>
  );
};

export default App;
