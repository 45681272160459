import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, Container } from '@material-ui/core';
import { Image } from 'components/atoms';
import { Section } from 'components/organisms';
import { LocalizationStore } from 'states';
const useStyles = makeStyles((theme) => ({
  root: {},
  folioItem: {
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.spacing(2),
    height: 200,
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
    '&:hover': {
      '& .folio__image': {
        transform: 'scale(1.2)',
      },
    },
  },
  image: {
    transitionDuration: '.7s',
    transform: 'scale(1.0)',
    width: '100%',
    objectFit: 'cover',
    height: 200,
    [theme.breakpoints.up('md')]: {
      height: 200,
    },
  },
  folioInfoWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    alignItems: 'flex-end',
    backgroundColor: 'rgba(27, 22, 66, 0.6)',
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  folioTitle: {
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  folioSubtitle: {
    color: 'white',
    textTransform: 'capitalize',
    margin: theme.spacing(1, 0),
  },
}));

const Folio = (props) => {
  let history = useHistory();
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));

  const { data, path, product, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Section>
      <div className={clsx(classes.root, className)} {...rest}>
        <Grid container spacing={2}>
          {data.map((item, index) => (
            <Grid key={index} item xs={6} md={4}>
              <div
                className={classes.folioItem}
                key={index}
                data-aos="fade-left"
                onClick={() => {
                  product
                    ? history.push(`/urun/${item.seoUrl}`)
                    : item.type === 'product'
                    ? history.push(`/urun/${item.seoUrl}`)
                    : history.push(`${path}/${item.seoUrl}`);
                }}
              >
                <Image
                  src={
                    item.image
                      ? item.image[0]
                        ? `${process.env.REACT_APP_BACKEND_URL}${item.image[0].url}`
                        : item.image.url
                        ? `${process.env.REACT_APP_BACKEND_URL}${item.image.url}`
                        : `${process.env.REACT_APP_BACKEND_URL}/uploads/soru_isareti_b7ff22bbc5.png`
                      : `${process.env.REACT_APP_BACKEND_URL}/uploads/soru_isareti_b7ff22bbc5.png`
                  }
                  alt={item.nameTR}
                  className={clsx('folio__image', classes.image)}
                  lazy={false}
                />
                <div className={classes.folioInfoWrapper}>
                  <Typography variant="h6" className={classes.folioTitle}>
                    {locale.language === 'Türkçe' ? item.nameTR : item.nameEN}
                  </Typography>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </Section>
  );
};

Folio.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Folio;
