import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  Divider,
  Button,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { LoadingCircular } from 'components/molecules';
import { SectionAlternate, CardBase } from 'components/organisms';
import { Hero } from './components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { PRODUCT_GQL } from 'utils/queries';
import { CheckCircle } from '@material-ui/icons';
import { LocalizationStore } from 'states';
import { Markdown } from 'utils';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  section: {
    '& .section-alternate__content': {
      paddingTop: 0,
      marginTop: theme.spacing(-5),
      position: 'relative',
      zIndex: 1,
    },
    '& .card-base__content': {
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
      },
    },
  },
  menu: {
    height: 'auto',
  },
  list: {
    display: 'inline-flex',
    overflow: 'auto',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(-3),
      marginLeft: theme.spacing(-3),
    },
  },
  listItem: {
    marginRight: theme.spacing(2),
    flex: 0,
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      borderLeft: '2px solid transparent',
    },
  },
  listItemActive: {
    [theme.breakpoints.up('md')]: {
      borderLeft: `2px solid ${theme.palette.primary.dark}`,
    },
    '& .menu__item': {
      color: theme.palette.text.primary,
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box component="div" hidden={value !== index} {...other}>
      {value === index && children}
    </Box>
  );
};

const subPages = [
  {
    titleEN: 'General Information',
    titleTR: 'Genel Bilgiler',
  },
  {
    titleEN: 'Photos',
    titleTR: 'Fotoğraflar',
  },
  {
    titleEN: 'Brochure',
    titleTR: 'Broşür',
  },
];

const ProductPage = () => {
  const [tab, setTab] = useState(0);
  const classes = useStyles();
  let { productID } = useParams();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));
  const { loading, error, data } = useQuery(PRODUCT_GQL(productID));

  if (loading) return <LoadingCircular />;
  if (error) return <p>Error :(</p>;

  return (
    <div className={classes.root}>
      <Hero
        cover={`${process.env.REACT_APP_BACKEND_URL}${data.ndts[0].image[0].url}`}
        title={
          locale.language === 'Türkçe'
            ? data.ndts[0].nameTR
            : data.ndts[0].nameEN
        }
      />
      <SectionAlternate className={classes.section}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <CardBase withShadow align="left" className={classes.menu}>
              <List disablePadding className={classes.list}>
                {subPages.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => setTab(index)}
                    className={clsx(
                      classes.listItem,
                      tab === index ? classes.listItemActive : {},
                    )}
                    disableGutters
                  >
                    <Typography
                      variant="subtitle1"
                      noWrap
                      color="textSecondary"
                      className="menu__item"
                    >
                      {locale.language === 'Türkçe'
                        ? item.titleTR
                        : item.titleEN}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </CardBase>
          </Grid>
          <Grid item xs={12} md={9}>
            <CardBase withShadow align="left">
              <TabPanel value={tab} index={0}>
                <Grid container spacing={isMd ? 4 : 2}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary">
                      {locale.language === 'Türkçe'
                        ? 'Genel Bilgiler'
                        : 'General Information'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Markdown
                      source={
                        locale.language === 'Türkçe'
                          ? data.ndts[0].infoTR
                          : data.ndts[0].infoEN
                      }
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1} style={{ width: '100%' }}>
                <Grid container spacing={isMd ? 4 : 2}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary">
                      {locale.language === 'Türkçe' ? 'Fotoğraflar' : 'Photos'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  {data.ndts[0].image.length > 0 &&
                    data.ndts[0].image.map((image, index) => {
                      return (
                        <Grid key={index} item xs={12} md={6}>
                          <img
                            src={`${process.env.REACT_APP_BACKEND_URL}${image.url}`}
                            style={{
                              width: '100%',
                              height: 'auto',
                            }}
                          />
                        </Grid>
                      );
                    })}

                  <Grid item xs={12} sm={6}></Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={2} style={{ width: '100%' }}>
                <Grid container spacing={isMd ? 4 : 2}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary">
                      {locale.language === 'Türkçe' ? 'Broşür' : 'Brochure'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {data.ndts[0].pdf.length > 0 ? (
                      data.ndts[0].pdf.map((item, index) => {
                        return (
                          <Button
                            fullWidth
                            endIcon={<CloudDownloadIcon />}
                            key={index}
                            variant="outlined"
                            href={`${process.env.REACT_APP_BACKEND_URL}${item.url}`}
                            target="_blank"
                            color="primary"
                            style={{ marginBottom: 20 }}
                          >
                            {item.name}
                          </Button>
                        );
                      })
                    ) : (
                      <Button variant="outlined" color="primary" disabled>
                        {locale.language === 'Türkçe'
                          ? 'PDF BULUNAMADI'
                          : 'PDF NOT FOUND'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
            </CardBase>
          </Grid>
        </Grid>
      </SectionAlternate>
    </div>
  );
};

export default ProductPage;
