import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Grid, Typography } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { useQuery } from '@apollo/client';
import { CONTACT_GQL, CATEGORIES_SUB_CATEGORIES } from 'utils/queries';
import { Image } from 'components/atoms';
import { LocalizationStore } from 'states';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 0),
    },
    background: '#1A1734',
  },
  footerContainer: {
    maxWidth: 1300,

    margin: '0 auto',
    padding: theme.spacing(0, 4),
    paddingBottom: theme.spacing(6),
  },
  gridContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoContainer: {
    width: 80,
    height: 80,
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  groupTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1),
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
  menuListContainer: {
    padding: '0 !important',
  },
  menu: {
    display: 'flex',
  },
  menuItem: {
    margin: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1 / 2),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
    color: 'white',
  },
  divider: {
    width: '100%',
  },
  navLink: {
    color: 'rgba(255,255,255,.6)',
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;
  const { locale } = LocalizationStore.useState((s) => ({
    locale: s.locale,
  }));
  const classes = useStyles();
  const { loading: catLoading, error: catError, data: catData } = useQuery(
    CATEGORIES_SUB_CATEGORIES,
  );
  const { loading, error, data } = useQuery(CONTACT_GQL);
  if (catLoading) return null;
  if (catError) return null;
  if (loading) return null;
  if (error) return null;

  console.log(catData);
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className={classes.gridContainer}>
              <div className={classes.logoContainer}>
                <a href="/" title="netan">
                  <Image
                    className={classes.logoImage}
                    src="/images/logos/netan-logo.jpg"
                    alt="netan"
                    lazy={false}
                  />
                </a>
              </div>
            </div>
          </Grid>
          <Grid item spacing={2} container xs={12} md={4}>
            <Grid item xs={12}>
              <Typography color="textSecondary" variant="h6">
                {locale.language === 'Türkçe' ? 'İletişim' : 'Contact'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                {locale.language === 'Türkçe' ? 'Telefon : ' : 'Phone : '}
                {data.contact.phone}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                {locale.language === 'Türkçe' ? 'Fax : ' : 'Fax : '}
                {data.contact.fax}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                {locale.language === 'Türkçe' ? 'E-posta : ' : 'E-mail : '}
                {data.contact.email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                {locale.language === 'Türkçe' ? 'Adres : ' : 'Adress : '}
                {locale.language === 'Türkçe'
                  ? data.contact.adressTR
                  : data.contact.adressEN}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={4}>
            <Grid item xs={12}>
              <Typography color="textSecondary" variant="h6">
                {locale.language === 'Türkçe' ? 'Ürün Gruplarımız' : 'Products'}
              </Typography>
            </Grid>
            {catData.categories.map((item, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ChevronRightIcon
                      style={{ color: '#546e7a', marginRight: 10 }}
                    />
                    <Typography
                      component={RouterLink}
                      to={`/urunler/${item.seoUrl}`}
                      color="textSecondary"
                    >
                      {item.nameTR}
                    </Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <Grid item spacing={4} container xs={12} md={4}>
            <Grid item xs={4}>
              <div className={classes.gridContainer}>
                <IconButton
                  className={classes.socialIcon}
                  target="_blank"
                  href={data.contact.facebook}
                >
                  <FacebookIcon className={classes.icon} />
                </IconButton>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className={classes.gridContainer}>
                <IconButton
                  className={classes.socialIcon}
                  target="_blank"
                  href={data.contact.instagram}
                >
                  <InstagramIcon className={classes.icon} />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.gridContainer}>
                <IconButton
                  className={classes.socialIcon}
                  target="_blank"
                  href={data.contact.twitter}
                >
                  <TwitterIcon className={classes.icon} />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.gridContainer}>
                <Typography color="textSecondary" align="center">
                  Copyright © {new Date().getFullYear()}
                  <br />
                  www.netan.com.tr
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
