import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { Form } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
}));

const ContactPageSidebarMap = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Form />

      <Divider />
    </div>
  );
};

export default ContactPageSidebarMap;
