import { gql } from '@apollo/client';

export const CATEGORIES_SUB_CATEGORIES = gql`
  query {
    categories {
      nameTR
      seoUrl
      nameEN
      sub_categories {
        nameTR
        seoUrl
        nameEN
      }
    }
  }
`;

export const CATEGORIES_GQL = gql`
  query {
    categories {
      nameTR
      seoUrl
      nameEN
      image {
        url
      }
    }
  }
`;

export const SUB_CATEGORIES_GQL = (seoUrl) => gql`
  query {
    categories(where: { seoUrl: "${seoUrl}" }) {
      nameTR
      nameEN
      seoUrl
      sub_categories {
        nameTR
        seoUrl
        nameEN
        image {
          url
        }
      }
    }
  }
`;

export const GROUPS_GQL = (seoUrl) => gql`
query {
  subCategories(where:{seoUrl:"${seoUrl}"}){
    nameTR
    nameEN
    seoUrl
    groups{
      nameTR
      nameEN
      seoUrl
      image{
        url
      }
    }
    products{
      seoUrl
      group{
        nameTR
        nameEN
      }
      sub_group{
        nameTR
        nameEN
      }
      nameTR
      nameEN
      id
      image{
        url
      }
    }
  }
}
`;

export const SUB_GROUPS_GQL = (seoUrl) => gql`
query {
  groups(where:{seoUrl:"${seoUrl}"}){
    nameTR
    nameEN
    seoUrl
    sub_groups{
      nameTR
      nameEN
      seoUrl
      image{
        url
      }
    }
    products{
      group{
        nameTR
        nameEN
        seoUrl
      }
      sub_group{
        nameTR
        nameEN
        seoUrl
      }
      seoUrl
      nameTR
      nameEN
      id
      image{
        url
      }
    }
  }
}
`;

export const SUB_GROUPS_PRODUCTS_GQL = (seoUrl) => gql`
query {
  subGroups(where:{seoUrl:"${seoUrl}"}){
    nameTR
    nameEN
    seoUrl
    products{
      nameTR
      nameEN
      seoUrl
      id
      image{
        url
      }
    }
  }
}
`;
export const PRODUCT_GQL = (seoUrl) => gql`
query {
  ndts(where:{seoUrl:"${seoUrl}"}){
    nameTR
    nameEN
    seoUrl
    pdf{
      url
      name
    }
    image{
      url
    }
    infoEN
    infoTR
  }
}
`;

export const ABOUT_GQL = gql`
  query {
    about {
      infoTR
      infoEN
      contactInfoTR
      contactInfoEN
      contactTitleTR
      concactTitleEN
      contactUsTR
      contactUsEN
    }
  }
`;

export const CONTACT_GQL = gql`
  query {
    contact {
      phone
      email
      fax
      locationE
      locationN
      facebook
      twitter
      instagram
      adressTR
      adressEN
    }
  }
`;

export const HOME_GQL = gql`
  query {
    home {
      Slider {
        image {
          url
        }
        titleTR
        titleEN
        subTitleTR
        subTitleEN
      }
    }
  }
`;

export const RANDROM_PRODUCTS_GQL = gql`
  {
    ndts(limit: 20) {
      nameTR
      nameEN
      image {
        url
      }
      seoUrl
    }
  }
`;
